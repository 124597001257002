import React from "react";
import "./DayColumnStyle.css";

const convertSemestersToScheduleItems = (
  semesters,
  semesterSelected,
  dayTranslate,
  currentDay
) => {
  const selectedSemester = semesters[semesterSelected];
  let scheduleItems = [];

  selectedSemester.forEach((course) => {
    const { name, lecturer, schedule_items } = course;

    schedule_items.forEach((schedule) => {
      const { day, start, end, room } = schedule;

      if (dayTranslate[day] == currentDay) {
        const scheduleItem = {
          name,
          lecturer,
          day,
          start,
          end,
          room,
        };
        scheduleItems.push(scheduleItem);
      }
    });
  });

  return scheduleItems;
};

const getStartPos = (startTime, minHour) => {
  const [hour, minute] = startTime.split(".");
  const startHour = hour - minHour;

  return startHour * 56 + (minute / 60) * 56;
};

const convertTo24HourFormat = (timeString) => {
  const [hour, minute] = timeString.split(".");
  return `${hour.padStart(2, "0")}:${minute.padStart(2, "0")}`;
};

const getHeight = (startTime, endTime) => {
  const start = convertTo24HourFormat(startTime);
  const end = convertTo24HourFormat(endTime);

  const startDate = new Date(`2000-01-01T${start}`);
  const endDate = new Date(`2000-01-01T${end}`);
  const durationInMillis = endDate - startDate;
  const durationInMinutes = durationInMillis / 60000;
  return (durationInMinutes / 60) * 56;
};

const generateNonClashingSchedules = (semester) => {
  const output = [];

  const sortedSemester = semester.sort((a, b) => {
    return a.start.localeCompare(b.start);
  });

  for (const schedule of sortedSemester) {
    let isAdded = false;

    for (const arr of output) {
      let clashFound = false;

      for (const existingSchedule of arr) {
        if (
          (schedule.start >= existingSchedule.start &&
            schedule.start < existingSchedule.end) ||
          (schedule.end > existingSchedule.start &&
            schedule.end <= existingSchedule.end)
        ) {
          clashFound = true;
          break;
        }
      }
      if (!clashFound) {
        arr.push(schedule);
        isAdded = true;
        break;
      }
    }

    if (!isAdded) {
      output.push([schedule]);
    }
  }

  return output;
};

const DayColumn = ({
  day,
  semesters,
  semesterSelected,
  colorDictionary,
  minHour,
  maxHour,
}) => {
  const dayTranslate = {
    Senin: "MON",
    Selasa: "TUE",
    Rabu: "WED",
    Kamis: "THU",
    Jumat: "FRI",
    Sabtu: "SAT",
    Minggu: "SUN",
  };

  const scheduleItems =
    Object.keys(semesters).length > 0
      ? convertSemestersToScheduleItems(
          semesters,
          semesterSelected,
          dayTranslate,
          day
        )
      : [];

  const scheduleItemsArr = generateNonClashingSchedules(scheduleItems);

  return (
    <div
      className={
        scheduleItemsArr.length > 0 ? "day-column" : "day-column empty"
      }
    >
      <div className="day-label">{day}</div>
      <div
        className="col-container"
        style={{ height: `calc(56px * (${maxHour - minHour + 1}))` }}
      >
        {scheduleItemsArr.map((schedule) => (
          <div className="col-item" key={crypto.randomUUID()}>
            {schedule.map((item, index) => {
              const height = getHeight(item.start, item.end);
              const startPos = getStartPos(item.start, minHour);

              const style = {
                height: `${height}px`,
                top: `${startPos}px`,
                backgroundColor: `var(--${
                  colorDictionary[item.name]
                }-secondary)`,
              };

              return (
                <div className="schedule-item" style={style} key={crypto.randomUUID()}>
                  <p
                    className="course-name"
                    style={{
                      color: `var(--${colorDictionary[item.name]}-dark)`,
                    }}
                  >
                    {item.name}
                  </p>
                  <p
                    style={{
                      color: `var(--${colorDictionary[item.name]}-dark)`,
                    }}
                  >
                    {item.room}
                  </p>
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DayColumn;
