import React, { useState, useEffect } from "react";
import "./TimetableBoxStyle.css";
import TimeLabelTimetable from "./TimeLabelTimetable";
import TimetableDay from "./TimetableDay";

const TimetableBox = ({
  semester,
  timetableData,
  setTimetableData,
  colorDictionary,
  scheduleItems,
  courseClasses,
  clickedCourse,
  setClickedCourse,
}) => {
  const [minHour, setMinHour] = useState(9);
  const [maxHour, setMaxHour] = useState(17);

  useEffect(() => {
    if (scheduleItems.length != 0) {
      let minStartTime = 24;
      let maxEndTime = 0;

      scheduleItems.forEach((item) => {
        const startTime = parseFloat(item.start);
        const endTime = parseFloat(item.end);

        if (startTime < minStartTime) {
          minStartTime = Math.floor(startTime);
        }

        if (endTime > maxEndTime) {
          maxEndTime = Math.ceil(endTime);
        }
      });

      if (minStartTime > 9) {
        minStartTime = 9;
      }
      if (maxEndTime < 17) {
        maxEndTime = 17;
      }
      setMinHour(minStartTime);
      setMaxHour(maxEndTime);
    }
  }, [scheduleItems]);

  return (
    <div className="timetable-box" id="timetable-box">
      <TimeLabelTimetable minHour={minHour} maxHour={maxHour} />
      <div className="days">
        <TimetableDay
          day="MON"
          minHour={minHour}
          maxHour={maxHour}
          scheduleItems={scheduleItems}
          colorDictionary={colorDictionary}
          courseClasses={courseClasses}
          clickedCourse={clickedCourse}
          setClickedCourse={setClickedCourse}
          timetableData={timetableData}
          setTimetableData={setTimetableData}
        />
        <TimetableDay
          day="TUE"
          minHour={minHour}
          maxHour={maxHour}
          scheduleItems={scheduleItems}
          colorDictionary={colorDictionary}
          courseClasses={courseClasses}
          clickedCourse={clickedCourse}
          setClickedCourse={setClickedCourse}
          timetableData={timetableData}
          setTimetableData={setTimetableData}
        />
        <TimetableDay
          day="WED"
          minHour={minHour}
          maxHour={maxHour}
          scheduleItems={scheduleItems}
          colorDictionary={colorDictionary}
          courseClasses={courseClasses}
          clickedCourse={clickedCourse}
          setClickedCourse={setClickedCourse}
          timetableData={timetableData}
          setTimetableData={setTimetableData}
        />
        <TimetableDay
          day="THU"
          minHour={minHour}
          maxHour={maxHour}
          scheduleItems={scheduleItems}
          colorDictionary={colorDictionary}
          courseClasses={courseClasses}
          clickedCourse={clickedCourse}
          setClickedCourse={setClickedCourse}
          timetableData={timetableData}
          setTimetableData={setTimetableData}
        />
        <TimetableDay
          day="FRI"
          minHour={minHour}
          maxHour={maxHour}
          scheduleItems={scheduleItems}
          colorDictionary={colorDictionary}
          courseClasses={courseClasses}
          clickedCourse={clickedCourse}
          setClickedCourse={setClickedCourse}
          timetableData={timetableData}
          setTimetableData={setTimetableData}
        />
      </div>
    </div>
  );
};

export default TimetableBox;
