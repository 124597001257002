import academicCalendarJSON from "../../assets/academic_calendar.json";

function getSemesterDates(semester) {
  return academicCalendarJSON[semester];
}

export function generateICS(semester, scheduleItems) {
  const ics = require("ics");

  const dayTranslate = {
    Senin: "MO",
    Selasa: "TU",
    Rabu: "WE",
    Kamis: "TH",
    Jumat: "FR",
    Sabtu: "SA",
    Minggu: "SU",
  };

  const getAdjustedStartDate = (scheduleDay, startDate) => {
    const daysOfWeek = [
      "SUNDAY",
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
    ];

    const scheduleDayIndex = daysOfWeek.indexOf(scheduleDay.toUpperCase());
    const startDateObj = new Date(startDate);
    const startDayIndex = startDateObj.getDay();
    const dayDifference = scheduleDayIndex - startDayIndex;
    startDateObj.setDate(startDateObj.getDate() + dayDifference);

    return startDateObj;
  };

  const semesterDates = getSemesterDates(semester);
  const startDateRaw = new Date(semesterDates["start"]);
  const endDate = new Date(semesterDates["end"]);

  const events = scheduleItems.map((item) => {
    const freq = "WEEKLY";
    const until = endDate.toISOString().replace(/-|:|\.\d+/g, "");
    const byday = dayTranslate[item.day];
    const rruleString = `FREQ=${freq};BYDAY=${byday};UNTIL=${until}`;
    const startDate = getAdjustedStartDate(item.day, startDateRaw);

    return {
      title: item.course_name + " - " + item.class,
      start: [
        startDate.getFullYear(),
        startDate.getMonth() + 1,
        startDate.getDate(),
        parseInt(item.start.slice(0, 2)),
        parseInt(item.start.slice(3)),
      ],
      end: [
        startDate.getFullYear(),
        startDate.getMonth() + 1,
        startDate.getDate(),
        parseInt(item.end.slice(0, 2)),
        parseInt(item.end.slice(3)),
      ],
      location: item.room,
      recurrenceRule: rruleString,
      productId: "matkulgue",
    };
  });

  const { error, value } = ics.createEvents(events);

  if (error) {
    console.error(error);
  } else {
    const icsFileContent = value;
    return icsFileContent;
  }
}
