import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home/Home";
import Courses from "./pages/Courses/Courses";
import CourseDetails from "./pages/CourseDetails/CourseDetails";
import Timetable from "./pages/Timetable/Timetable";
import "react-toastify/dist/ReactToastify.css";
import TimetableExportOriginal from "./components/TimetableExport/Original/TimetableExportOriginal";
import TimetableExportWallpaper from "./components/TimetableExport/Wallpaper/TimetableExportWallpaper";
import ReactGA from "react-ga4";

function App() {
  ReactGA.initialize(process.env.REACT_APP_MEASUREMENT_ID);
  return (
    <>
      <Router basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route
            exact
            path="/"
            element={<Navigate to="/courses" replace={true} />}
          />

          <Route
            path="/courses"
            element={
              <>
                <Navbar />
                <Courses />
                <Footer />
              </>
            }
          />

          <Route
            path="/courses/:course_code/:curriculum"
            element={
              <>
                <Navbar />
                <CourseDetails />
                <Footer />
              </>
            }
          />

          <Route
            path="/timetable/sem-1"
            element={
              <>
                <Navbar />
                <Timetable semNumber={1} />
                <Footer />
              </>
            }
          />
          <Route
            path="/timetable/sem-2"
            element={
              <>
                <Navbar />
                <Timetable semNumber={2} />
                <Footer />
              </>
            }
          />
          <Route
            path="/timetable/sp"
            element={
              <>
                <Navbar />
                <Timetable semNumber={3} />
                <Footer />
              </>
            }
          />

          <Route
            path="/timetable/export/:semNumber"
            element={<TimetableExportOriginal />}
          />

          <Route
            path="/timetable/export-wallpaper/:semNumber"
            element={<TimetableExportWallpaper />}
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
