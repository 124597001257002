import ReactGA from "react-ga4";

const AnalyticsEventTracker = (category) => {
  const eventTracker = (action) => {
    ReactGA.event({ category, action });
  };
  return eventTracker;
};

export default AnalyticsEventTracker;
