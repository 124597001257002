import React from "react";
import "./SemesterButtonStyle.css";

const SemesterButton = ({ semester, onClick, selected }) => {
  return (
    <div
      className={selected ? "semester-button-active" : "semester-button"}
      onClick={onClick}
    >
      <p>Semester {semester != "3" ? semester : "Pendek"}</p>
    </div>
  );
};

export default SemesterButton;
