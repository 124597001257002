import React, { useState } from "react";
import "./NavbarStyle.css";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/logo_horizontal.png";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const handleLinkClick = () => {
    if (nav) {
      setNav(false);
    }
  };

  return (
    <nav>
      <div className="nav-container">
        <NavLink className="navlink" to="/courses" onClick={handleLinkClick}>
          <img className="nav-logo" src={Logo} alt="Logo" />
        </NavLink>

        <ul className="nav-menu">
          <li>
            <NavLink
              className="navlink"
              to="/courses"
              onClick={handleLinkClick}
            >
              Courses
            </NavLink>
          </li>
          <li>
            <NavLink
              className="navlink"
              to="/timetable/sem-1"
              onClick={handleLinkClick}
            >
              Timetable
            </NavLink>
          </li>
          <li>
            <NavLink
              className="navlink"
              to="https://forms.gle/wYb4JeNW7rbZp9Wh7"
              target="_blank"
              onClick={handleLinkClick}
            >
              Feedback
            </NavLink>
          </li>
        </ul>

        <div onClick={handleNav} className="block hamburger">
          {nav ? <AiOutlineClose size={24} /> : <AiOutlineMenu size={24} />}
        </div>

        <ul className={nav ? "nav-mobile-active" : "nav-mobile-inactive"}>
          <li>
            <NavLink
              className="navlink mobile"
              to="/courses"
              onClick={handleLinkClick}
            >
              Courses
            </NavLink>
          </li>
          <li>
            <NavLink
              className="navlink mobile"
              to="/timetable/sem-1"
              onClick={handleLinkClick}
            >
              Timetable
            </NavLink>
          </li>
          <li>
            <NavLink
              className="navlink mobile"
              to="https://forms.gle/wYb4JeNW7rbZp9Wh7"
              target="_blank"
              onClick={handleLinkClick}
            >
              Feedback
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
