import React, { useState, useEffect } from "react";
import "./TimetableActionStyle.css";
import NoClashButton from "./NoClashButton";
import DownloadButton from "./DownloadButton";
import ShareButton from "./ShareButton";
import AddCourseInput from "./AddCourseInput";
import CourseItem from "./CourseItem";

const TimetableAction = ({
  semester,
  timetableData,
  courseClasses,
  colorDictionary,
  setTimetableData,
  scheduleItems,
}) => {
  const [coursesData, setCoursesData] = useState([]);

  useEffect(() => {
    if (timetableData.length > 0 && Object.keys(courseClasses).length > 0) {
      const formattedData = timetableData.map((item) => {
        const { course_code, curriculum, class: className } = item;
        const courseKey = `${course_code}/${curriculum}`;
        const course_name = courseClasses[courseKey]["course_name"];
        const courseClass = courseClasses[courseKey]["classes"];
        const credit = courseClasses[courseKey]["credit"];

        let lecturer = [];
        for (const item of courseClass) {
          if (item.name === className) {
            lecturer = item.lecturer;
            break;
          }
        }

        return {
          course_code,
          curriculum,
          course_name: course_name,
          credit: credit,
          class: className,
          lecturer: lecturer,
          classes: courseClass
            .filter((classItem) => classItem.name !== className)
            .map((classItem) => ({
              name: classItem.name,
              lecturer: classItem.lecturer,
            })),
        };
      });
      setCoursesData(formattedData);
    } else {
      setCoursesData([]);
    }
  }, [timetableData, courseClasses]);

  const totalCredit = Object.values(coursesData).reduce(
    (sum, item) => sum + parseInt(item.credit),
    0
  );

  return (
    <div>
      <NoClashButton
        setTimetableData={setTimetableData}
        courseClasses={courseClasses}
      />
      <div className="download-share-container">
        <DownloadButton semester={semester} scheduleItems={scheduleItems} />
        {/* <ShareButton /> */}
      </div>
      {/* <AddCourseInput /> */}
      {coursesData.map((item) => (
        <CourseItem
          key={crypto.randomUUID()}
          data={item}
          colorDictionary={colorDictionary}
          setTimetableData={setTimetableData}
        />
      ))}
      <div className="line-timetable-action"></div>
      <div className="total-credit">
        <p>Total SKS</p>
        <h6>{totalCredit} SKS</h6>
      </div>
      <div className="legend">
        <p>Data correct as 4 August 2023 21:13</p>
        <p>
          <bold>Semester 1</bold> : 2023/2024 Ganjil
        </p>
        <p>
          <bold>Semester 2</bold> : 2022/2023 Genap
        </p>
        <p>
          <bold>Semester 3</bold> : 2022/2023 Pendek
        </p>
      </div>
    </div>
  );
};

export default TimetableAction;
