import React, { useState, useEffect, useRef } from "react";
import "./DownloadButtonStyle.css";
import { HiOutlineDownload } from "react-icons/hi";
import { MdArrowDropDown } from "react-icons/md";
import { BsCardImage, BsFileImage, BsCalendarEvent } from "react-icons/bs";
import { generateICS } from "./icsGenerator";
import AnalyticsEventTracker from "../../helper/AnalyticsEventTracker";

const DownloadButton = ({ semester, scheduleItems }) => {
  const gaEventTracker = AnalyticsEventTracker("Download");
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  const handleDownloadICS = () => {
    gaEventTracker("Download timetable calendar");
    const icsData = generateICS(semester, scheduleItems);
    const icsBlob = new Blob([icsData], { type: "text/calendar" });
    const downloadURL = URL.createObjectURL(icsBlob);

    const downloadLink = document.createElement("a");
    downloadLink.href = downloadURL;
    downloadLink.download = `timetable_sem${semester}_matkulgue.ics`;

    downloadLink.click();
  };

  const handleDownloadImage = () => {
    gaEventTracker("Download timetable image");
    const url = `/timetable/export/${semester}`;
    window.open(url, "_blank");
  };

  const handleDownloadWallpaper = () => {
    gaEventTracker("Download timetable wallpaper");
    const url = `/timetable/export-wallpaper/${semester}`;
    window.open(url, "_blank");
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="download-container">
      <div
        className="download-button"
        ref={dropdownRef}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <HiOutlineDownload size={18} />
        <p>Download</p>
        <MdArrowDropDown size={20} />
      </div>

      {showDropdown && (
        <div className="download-menu">
          <div className="download-item" onClick={handleDownloadImage}>
            <BsCardImage />
            <p>Image (.png)</p>
          </div>
          <div className="download-item" onClick={handleDownloadWallpaper}>
            <BsFileImage />
            <p>Phone Wallpaper (.png)</p>
          </div>
          <div className="download-item" onClick={handleDownloadICS}>
            <BsCalendarEvent />
            <p>Google/Apple Calendar (.ics)</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default DownloadButton;
