import React, { useState } from "react";
import "./ScheduleItemStyle.css";

const getStartPos = (startTime, minHour) => {
  const [hour, minute] = startTime.split(".");
  const startHour = hour - minHour;

  return startHour * 64 + (minute / 60) * 64;
};

const convertTo24HourFormat = (timeString) => {
  const [hour, minute] = timeString.split(".");
  return `${hour.padStart(2, "0")}:${minute.padStart(2, "0")}`;
};

const getHeight = (startTime, endTime) => {
  const start = convertTo24HourFormat(startTime);
  const end = convertTo24HourFormat(endTime);

  const startDate = new Date(`2000-01-01T${start}`);
  const endDate = new Date(`2000-01-01T${end}`);
  const durationInMillis = endDate - startDate;
  const durationInMinutes = durationInMillis / 60000;
  return (durationInMinutes / 60) * 64;
};

const ScheduleItem = ({
  minHour,
  maxHour,
  data,
  colorDictionary,
  courseClasses,
  clickedCourse,
  setClickedCourse,
  timetableData,
  setTimetableData,
}) => {
  const height = getHeight(data.start, data.end);
  const startPos = getStartPos(data.start, minHour);
  const clickable =
    courseClasses[data.course_code + "/" + data.curriculum]["classes"].length >
    1;
  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const handleClick = () => {
    if (clickedCourse === "") {
      setClickedCourse(data.course_code + "/" + data.curriculum);
    } else {
      setTimetableData((prevData) =>
        prevData.map((item) =>
          item.course_code === data.course_code &&
          item.curriculum === data.curriculum
            ? { ...item, class: data.class }
            : item
        )
      );
      setClickedCourse("");
    }
  };

  const style = {
    height: `${height}px`,
    top: `${startPos}px`,
    backgroundColor: `var(--${
      hover
        ? colorDictionary[data.course_code + "/" + data.curriculum] + "-primary"
        : colorDictionary[data.course_code + "/" + data.curriculum] +
          "-secondary"
    })`,
    opacity: data.isAdded || hover ? 1 : 0.5,
  };

  const words = data.course_name.split(" ");
  let abbreviation = "";

  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    if (/^[A-Za-z]+$/.test(word)) {
      abbreviation += word.charAt(0);
    }
  }

  return (
    <div
      className={clickable ? "schedule-item clickable" : "schedule-item"}
      style={style}
      key={crypto.randomUUID()}
      {...(clickable && {
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
        onClick: handleClick,
      })}
    >
      <p
        className="course-name"
        style={{
          color: `var(--${
            colorDictionary[data.course_code + "/" + data.curriculum]
          }-dark)`,
        }}
      >
        {abbreviation} - {data.class}
      </p>
      <p
        style={{
          color: `var(--${
            colorDictionary[data.course_code + "/" + data.curriculum]
          }-dark)`,
        }}
      >
        {data.room}
      </p>
    </div>
  );
};

export default ScheduleItem;
