import React from "react";
import "./NoDataFeedbackStyle.css";
import { AiFillWarning } from "react-icons/ai";

const NoDataFeedback = () => {
  return (
    <div className="no-data-feedback">
      <AiFillWarning size={64} className="icon"/>
      <h5>No results found</h5>
    </div>
  );
};

export default NoDataFeedback;
