import React, { useState } from "react";
import "./FilterMobileStyle.css";
import { FaFilter } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import Filter from "./Filter";

const FilterMobile = ({
  semesterFilters,
  onSemesterFilterChange,
  facultyFilters,
  onFacultyFilterChange,
}) => {
  const [filterIsClicked, setFilter] = useState(false);

  const handleFilter = () => {
    setFilter(!filterIsClicked);
  };

  return (
    <div className="filter-mobile">
      <div onClick={handleFilter} className="icon">
        {filterIsClicked ? (
          <AiOutlineClose size={24} />
        ) : (
          <FaFilter size={24} />
        )}
      </div>
      <div
        className={
          filterIsClicked ? "filter-mobile-active" : "filter-mobile-inactive"
        }
      >
        <Filter
          semesterFilters={semesterFilters}
          onSemesterFilterChange={onSemesterFilterChange}
          facultyFilters={facultyFilters}
          onFacultyFilterChange={onFacultyFilterChange}
        />
      </div>
    </div>
  );
};

export default FilterMobile;
