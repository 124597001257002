import React from "react";
import "./CourseCardStyle.css";
import { Link } from "react-router-dom";

const CourseCard = ({
  courseCode,
  courseName,
  faculty,
  major,
  credit,
  semester,
  description,
  curriculum,
}) => {
  // Capitalize the first character of each word in the faculty
  const formattedFaculty = faculty
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  const formattedMajor = major.replace(/\([^()]*\)/g, "").trim();
  const placeholderDescription =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc congue eget lacus vel dignissim. Donec facilisis, tellus eu tincidunt vehicula, arcu purus tempor nisi, vitae ultrices massa massa et risus. Vivamus non gravida lorem. Maecenas at elementum metus. Quisque velit nisl, porttitor in vestibulum et, egestas non eros. Curabitur iaculis velit id odio eleifend, suscipit pretium est consectetur. Morbi venenatis ante ut ipsum interdum, a malesuada elit sagittis. Vivamus sed diam erat. Curabitur neque neque, vehicula a odio ut, accumsan varius nulla. Ut id augue sit amet augue malesuada feugiat.";

  return (
    <div className="course-container">
      <div className="header-course-container">
        <div className="title-container">
          <Link to={"/courses/" + courseCode + "/" + curriculum}>
            <h5>
              {courseCode} {courseName}
            </h5>
          </Link>
          <p>
            {formattedFaculty} • {formattedMajor} • {credit} SKS
          </p>
        </div>
        <div className="sem-container">
          {semester.map((sem) => (
            <div key={crypto.randomUUID()} className="sem-wrapper">
              {sem !== 3 ? `Sem ${sem}` : "SP"}
            </div>
          ))}
        </div>
      </div>
      <p className="course-desc">
        {description !== "" ? description : placeholderDescription}
      </p>
    </div>
  );
};

export default CourseCard;
