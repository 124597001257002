import React from "react";
import "./LoadingPageStyle.css";
import SyncLoader from "react-spinners/SyncLoader";

const LoadingPage = () => {
  return (
    <div className="loading-page">
      <SyncLoader
        loading={true}
        color="#ffd900"
        cssOverride={{
          color: "var(--yellow-primary)",
          width: "fit-content",
        }}
        size={20}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default LoadingPage;
