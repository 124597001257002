import React, { useState, useEffect } from "react";
import SemesterButton from "./SemesterButton";
import TimetableSemester from "./TimetableSemester";
import "./TimetableCourseStyle.css";

const TimetableCourse = ({ semesters }) => {
  const semester = Object.keys(semesters);
  const [selectedSemester, setSelectedSemester] = useState(semester[0]);

  const handleSemesterClick = (semester) => {
    setSelectedSemester(semester);
  };

  return (
    <div>
      <div className="semester-button-container">
        {semester.map((sem) => (
          <SemesterButton
            semester={sem}
            onClick={() => handleSemesterClick(sem)}
            selected={selectedSemester === sem}
            key={crypto.randomUUID()}
          />
        ))}
      </div>
      <TimetableSemester
        semesters={semesters}
        semesterSelected={selectedSemester}
      />
    </div>
  );
};

export default TimetableCourse;
