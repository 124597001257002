import React, { useState, useEffect } from "react";
import "./FilterStyle.css";
import Checkbox from "../Checkbox/Checkbox";

const Filter = ({
  semesterFilters,
  onSemesterFilterChange,
  facultyFilters,
  onFacultyFilterChange,
}) => {
  const faculties = [
    "Ekonomi & Bisnis",
    "Farmasi",
    "Hukum",
    "Ilmu Administrasi",
    "Ilmu Keperawatan",
    "Ilmu Komputer",
    "Ilmu Lingkungan",
    "Ilmu Pengetahuan Budaya",
    "Ilmu Sosial & Ilmu Politik",
    "Kajian Stratejik & Global",
    "Kedokteran",
    "Kedokteran Gigi",
    "Kesehatan Masyarakat",
    "Matematika & Ilmu Pengetahuan Alam",
    "Pendidikan Vokasi",
    "Perolehan Kredit",
    "Psikologi",
    "Teknik",
  ];

  return (
    <div className="filter-container">
      <div className="filter-clear">
        <p className="filter">Filter</p>
        <p className="clear">CLEAR</p>
      </div>

      <div className="filter-box">
        <div className="filter-box-container">
          <p className="title">Offered In</p>
          <Checkbox
            label="Semester 1"
            onChange={() => onSemesterFilterChange(1)}
            checked={semesterFilters.includes(1)}
          />
          <Checkbox
            label="Semester 2"
            onChange={() => onSemesterFilterChange(2)}
            checked={semesterFilters.includes(2)}
          />
          <Checkbox
            label="Semester Pendek"
            onChange={() => onSemesterFilterChange(3)}
            checked={semesterFilters.includes(3)}
          />
        </div>
        <div className="filter-box-container">
          <p className="title">Faculty</p>
          {faculties.map((faculty, index) => (
            <Checkbox
              key={index}
              label={faculty}
              onChange={() => onFacultyFilterChange(faculty)}
              checked={facultyFilters.includes(faculty)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Filter;
