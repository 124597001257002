import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import TimetableBox from "../../Timetable/TimetableBox";
import courseDetailsService from "../../../services/courseDetails.service";
import CoursesList from "./CoursesList";
import { convertToImage } from "./DownloadTimetableImageOriginal";
import downloadIllustration from "../../../assets/download_illustration.png";
import "./TimetableExportOriginalStyle.css";

const TimetableExportOriginal = () => {
  const { semNumber } = useParams();
  const [scheduleItems, setScheduleItems] = useState([]);
  const [courseClasses, setCourseClasses] = useState({});
  const [isLoad, setIsLoad] = useState(false);
  const [clickedCourse, setClickedCourse] = useState("");
  const courseColor = [
    "red",
    "orange",
    "sun",
    "green",
    "mint",
    "sea",
    "lightbrown",
    "pastel",
    "blue",
    "brown",
    "purple",
    "darkblue",
  ];

  const getDataFromLocalStorage = () => {
    let localData = localStorage.getItem("courseData");
    localData = JSON.parse(localData);
    return localData[semNumber] || [];
  };

  const [timetableData, setTimetableData] = useState(() => {
    const storedData = getDataFromLocalStorage();
    return storedData ? storedData : [];
  });

  // load data from local storage
  useEffect(() => {
    const localData = getDataFromLocalStorage();
    setTimetableData(localData);
  }, []);

  const getColorDictionary = (timetableData) => {
    const colorDictionary = {};

    for (const data of timetableData) {
      const { course_code, curriculum } = data;
      const key = course_code + "/" + curriculum;
      const color =
        courseColor[Object.keys(colorDictionary).length % courseColor.length];
      colorDictionary[key] = color;
    }

    return colorDictionary;
  };

  const colorDictionary = getColorDictionary(timetableData);

  // fetching the details of courses
  useEffect(() => {
    const fetchCourseDetails = async () => {
      const scheduleItemsData = [];
      const courseClassesData = {};

      for (const data of timetableData) {
        const { course_code, curriculum, class: className } = data;

        try {
          const response = await courseDetailsService.getCourseDetails(
            course_code,
            curriculum
          );
          const courseDetails = response.data;

          if (courseDetails && courseDetails.semesters) {
            const semesterItems = courseDetails.semesters[semNumber] || [];
            const filteredItems = semesterItems
              .filter((item) => item.name === className)
              .flatMap((item) => item.schedule_items);

            for (const item of filteredItems) {
              item["course_name"] = courseDetails["name"];
              item["course_code"] = course_code;
              item["curriculum"] = curriculum;
              item["class"] = className;
              item["isAdded"] = true;
              scheduleItemsData.push(item);
            }

            const key = course_code + "/" + curriculum;
            courseClassesData[key] = {
              course_name: courseDetails.name,
              credit: courseDetails.credit,
              classes: courseDetails.semesters[semNumber],
            };
          }
        } catch (error) {
          console.log(error);
        }
      }
      setScheduleItems(scheduleItemsData);
      setCourseClasses(courseClassesData);
      setIsLoad(true);
    };

    fetchCourseDetails();
  }, [semNumber, timetableData]);

  useEffect(() => {
    if (isLoad) {
      convertToImage();
    }
  }, [isLoad]);

  return (
    <>
      <div className="timetable-export-original" id="timetable-export-original">
        <TimetableBox
          semester={semNumber}
          timetableData={timetableData}
          setTimetableData={setTimetableData}
          colorDictionary={colorDictionary}
          scheduleItems={scheduleItems}
          courseClasses={courseClasses}
          clickedCourse={clickedCourse}
          setClickedCourse={setClickedCourse}
        />
        <CoursesList
          semester={semNumber}
          timetableData={timetableData}
          courseClasses={courseClasses}
          colorDictionary={colorDictionary}
          setTimetableData={setTimetableData}
          scheduleItems={scheduleItems}
        />
      </div>
      <div className="download-page">
        <img src={downloadIllustration} className="download-illustration" />
        <h5>Preparing to download your timetable ...</h5>
        <p>
          You should be redirected after downloaded. If not click{" "}
          <a onClick={() => window.close()}>here</a>
        </p>
      </div>
    </>
  );
};

export default TimetableExportOriginal;
