import React, { useState } from "react";
import "./CheckboxStyle.css";
import { BsCheckLg } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";

const Checkbox = ({ label, onChange, checked }) => {
  const [hovered, setHovered] = useState(false);

  const handleContainerHover = () => {
    setHovered(true);
  };

  const handleContainerLeave = () => {
    setHovered(false);
  };

  return (
    <>
      <div
        className="checkbox-container"
        onMouseEnter={handleContainerHover}
        onMouseLeave={handleContainerLeave}
      >
        <label htmlFor={label}>{label}</label>
        <div>
          <div className="container-input" onClick={onChange}>
            <input type="checkbox" checked={checked} id={label} onChange={onChange}/>
            <div
              className={`${
                checked && hovered
                  ? "container-input-cancel"
                  : checked
                  ? "container-input-after"
                  : "container-input-before"
              }`}
            >
              {checked && hovered ? (
                <RxCross2 size={20} />
              ) : checked ? (
                <BsCheckLg size={20} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkbox;
