import React, { useState, useEffect } from "react";
import CourseItem from "../../Timetable/CourseItem";

const CoursesList = ({
  semester,
  timetableData,
  courseClasses,
  colorDictionary,
  setTimetableData,
  scheduleItems,
}) => {
  const [coursesData, setCoursesData] = useState([]);

  useEffect(() => {
    if (timetableData.length > 0 && Object.keys(courseClasses).length > 0) {
      const formattedData = timetableData.map((item) => {
        const { course_code, curriculum, class: className } = item;
        const courseKey = `${course_code}/${curriculum}`;
        const course_name = courseClasses[courseKey]["course_name"];
        const courseClass = courseClasses[courseKey]["classes"];
        const credit = courseClasses[courseKey]["credit"];

        let lecturer = [];
        for (const item of courseClass) {
          if (item.name === className) {
            lecturer = item.lecturer;
            break;
          }
        }

        return {
          course_code,
          curriculum,
          course_name: course_name,
          credit: credit,
          class: className,
          lecturer: lecturer,
          classes: courseClass
            .filter((classItem) => classItem.name !== className)
            .map((classItem) => ({
              name: classItem.name,
              lecturer: classItem.lecturer,
            })),
        };
      });
      setCoursesData(formattedData);
    } else {
      setCoursesData([]);
    }
  }, [timetableData, courseClasses]);

  const totalCredit = Object.values(coursesData).reduce(
    (sum, item) => sum + parseInt(item.credit),
    0
  );

  return (
    <div>
      {coursesData.map((item) => (
        <CourseItem
          key={crypto.randomUUID()}
          data={item}
          colorDictionary={colorDictionary}
          setTimetableData={setTimetableData}
        />
      ))}
      <div className="line-timetable-action"></div>
      <div className="total-credit">
        <p>Total SKS</p>
        <h6>{totalCredit} SKS</h6>
      </div>
    </div>
  );
};

export default CoursesList;
