import React from "react";
import "./NoClashButtonStyle.css";
import { BsStars } from "react-icons/bs";
import { toast } from "react-toastify";
import AnalyticsEventTracker from "../../helper/AnalyticsEventTracker";

function generate_combinations(courseClasses) {
  let combinations = [];
  let keys = Object.keys(courseClasses);
  let current_combination = Array(keys.length).fill(null);

  function check_schedule_clash(schedule) {
    let time_slots = {};
    for (let class_item of schedule) {
      for (let schedule_item of class_item.schedule_items) {
        let day = schedule_item.day;
        let start_time = schedule_item.start;
        let end_time = schedule_item.end;
        if (day in time_slots) {
          for (let slot of time_slots[day]) {
            if (
              (start_time >= slot[0] && start_time < slot[1]) ||
              (end_time > slot[0] && end_time <= slot[1])
            ) {
              return true;
            }
          }
          time_slots[day].push([start_time, end_time]);
        } else {
          time_slots[day] = [[start_time, end_time]];
        }
      }
    }
    return false;
  }

  function serialize_combination(keys, combination) {
    let res = [];
    for (let i = 0; i < combination.length; i++) {
      res.push({
        course_code: keys[i].split("/")[0],
        curriculum: keys[i].split("/")[1],
        class: combination[i].name,
      });
    }
    return res;
  }

  function backtrack(index) {
    if (index === keys.length) {
      if (!check_schedule_clash(current_combination)) {
        combinations.push(serialize_combination(keys, current_combination));
      }
      return;
    }

    let course = keys[index];
    let classes = courseClasses[course].classes;

    for (let class_ of classes) {
      current_combination[index] = class_;
      backtrack(index + 1);
    }
  }

  backtrack(0);
  return combinations;
}

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}

const NoClashButton = ({ setTimetableData, courseClasses }) => {
  const findNonClashing = () => {
    const gaEventTracker = AnalyticsEventTracker("Click");
    gaEventTracker("Find Non-Clashing Schedule");
    if (Object.keys(courseClasses).length > 0) {
      const combinations = generate_combinations(courseClasses);
      if (combinations.length > 0) {
        const selected = combinations[getRandomInt(0, combinations.length)];
        setTimetableData(selected);
        toast.success("Your schedule is conflict-free", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error("No schedule without conflicts", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  return (
    <>
      <div
        className="no-clash-button"
        onClick={() => {
          findNonClashing();
        }}
      >
        <BsStars />
        <p>Find Non-Clashing Schedule</p>
      </div>
    </>
  );
};

export default NoClashButton;
