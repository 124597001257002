import React, { useState, useEffect, useRef } from "react";
import "./ButtonAddCourseStyle.css";
import { RiArrowDropDownLine } from "react-icons/ri";

const ButtonAddCourse = ({ semester, data }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const semesterItem = semester ? semester.slice(1) : [];
  const dropdownRef = useRef(null);
  const getDataFromLocalStorage = () => {
    const localData = localStorage.getItem("courseData");
    return JSON.parse(localData);
  };

  const [courseData, setCourseData] = useState(() => {
    const storedData = getDataFromLocalStorage();
    return storedData ? storedData : {};
  });
  const [isAdded, setIsAdded] = useState({ 1: false, 2: false, 3: false });

  const updateToCurrentLocalStorage = async () => {
    const localData = getDataFromLocalStorage();
    if (localData) {
      setCourseData(localData);

      const course_code = data.course_code;
      const curriculum = data.curriculum;

      const updatedIsAdded = { ...isAdded };
      Object.keys(localData).forEach((semesterKey) => {
        const semesterCourses = localData[semesterKey];
        const isCourseAdded = semesterCourses.some(
          (course) =>
            course.course_code === course_code &&
            course.curriculum === curriculum
        );
        updatedIsAdded[semesterKey] = isCourseAdded;
      });

      setIsAdded(updatedIsAdded);
    }
  };

  const handleSemesterClick = async (semesterNumber) => {
    await updateToCurrentLocalStorage();
    if (isAdded[semesterNumber] == false) {
      addCourseToSemester(semesterNumber, {
        course_code: data.course_code,
        curriculum: data.curriculum,
        class: data.semesters[semesterNumber][0].name,
      });
    } else {
      removeCourseFromSemester(
        semesterNumber,
        data.course_code,
        data.curriculum
      );
    }

    setIsAdded((prevIsAdded) => {
      return {
        ...prevIsAdded,
        [semesterNumber]: !prevIsAdded[semesterNumber],
      };
    });
  };

  const saveDataToLocalStorage = (data) => {
    localStorage.setItem("courseData", JSON.stringify(data));
  };

  const handleDropdownClick = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  const addCourseToSemester = (semesterNumber, courseDetails) => {
    setCourseData((prevData) => {
      const updatedData = { ...prevData };

      if (updatedData[semesterNumber]) {
        updatedData[semesterNumber].push(courseDetails);
      } else {
        updatedData[semesterNumber] = [courseDetails];
      }

      return updatedData;
    });
  };

  const removeCourseFromSemester = (semesterNumber, courseCode, curriculum) => {
    setCourseData((prevData) => {
      const updatedData = { ...prevData };

      if (updatedData[semesterNumber]) {
        updatedData[semesterNumber] = updatedData[semesterNumber].filter(
          (course) =>
            !(
              course.course_code === courseCode &&
              course.curriculum === curriculum
            )
        );
      }

      return updatedData;
    });
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const localData = getDataFromLocalStorage();
    if (localData) {
      setCourseData(localData);

      const course_code = data.course_code;
      const curriculum = data.curriculum;

      const updatedIsAdded = { ...isAdded };
      Object.keys(localData).forEach((semesterKey) => {
        const semesterCourses = localData[semesterKey];
        const isCourseAdded = semesterCourses.some(
          (course) =>
            course.course_code === course_code &&
            course.curriculum === curriculum
        );
        updatedIsAdded[semesterKey] = isCourseAdded;
      });

      setIsAdded(updatedIsAdded);
    }
  }, [data]);

  useEffect(() => {
    saveDataToLocalStorage(courseData);
  }, [courseData]);

  return (
    <>
      <div
        className={
          semesterItem.length > 0
            ? "button-add-course-grid"
            : "button-add-course"
        }
      >
        <div
          className="dropdown-left-col"
          onClick={() => handleSemesterClick(semester[0])}
        >
          <p>{isAdded[semester[0]] == true ? "Remove from" : "Add to"}</p>
          <h6>Semester {semester ? semester[0] : null}</h6>
        </div>
        {semesterItem.length > 0 && (
          <div
            key="dropdown-right-col"
            className="dropdown-right-col"
            onClick={handleDropdownClick}
            ref={dropdownRef}
          >
            <RiArrowDropDownLine size={28} />
          </div>
        )}
      </div>
      {dropdownVisible ? (
        <div className="dropdown-menu">
          {semesterItem.map((sem) => (
            <div
              className="dropdown-sem-item"
              key={crypto.randomUUID()}
              onClick={() => handleSemesterClick(sem)}
            >
              <p>
                {isAdded[sem] ? "Remove from Semester" : "Add to Semester"}{" "}
                {sem === 3 ? "Pendek" : sem}
              </p>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default ButtonAddCourse;
