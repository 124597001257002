import React from "react";
import "./InstructorListStyle.css";

const InstructorList = ({ semesters }) => {
  const semesterArr = Object.keys(semesters);
  return (
    <div className="instructor-list">
      {semesterArr.map((sem) => {
        const semesterClasses = semesters[sem];
        return (
          <div key={crypto.randomUUID()}>
            <h5>Semester {sem}</h5>
            {semesterClasses.map((classes) => (
              <div key={crypto.randomUUID()} className="instructor-container">
                <h6>{classes.name}</h6>
                {classes.lecturer.map((lec) => (
                  <p key={lec}>{lec}</p>
                ))}
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default InstructorList;
