import React, { useState, useEffect, useRef } from "react";
import "./CourseItemStyle.css";
import { FaTrash } from "react-icons/fa";
import { AiFillUnlock } from "react-icons/ai";
import { IoMdArrowDropdown } from "react-icons/io";

const CourseItem = ({ data, colorDictionary, setTimetableData }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const style = {
    backgroundColor: `var(--${
      colorDictionary[data.course_code + "/" + data.curriculum] + "-primary"
    })`,
  };

  const removeCourse = () => {
    setTimetableData((prevData) =>
      prevData.filter(
        (item) =>
          item.course_code !== data.course_code ||
          item.curriculum !== data.curriculum
      )
    );
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const changeClassClick = (className) => {
    setTimetableData((prevData) =>
      prevData.map((item) =>
        item.course_code === data.course_code &&
        item.curriculum === data.curriculum
          ? { ...item, class: className }
          : item
      )
    );
  };

  return (
    <div className="course-item">
      <div className="color-box" style={style} />

      <div className="details">
        <div className="info">
          <a href={`/courses/${data.course_code}/${data.curriculum}`}>
            <p className="course-name">{data.course_name}</p>
          </a>

          <div
            className={
              data.classes.length > 0 ? "class-item class-click" : "class-item"
            }
            onClick={
              data.classes.length > 0
                ? (e) => {
                    e.preventDefault();
                    setShowDropdown(!showDropdown);
                  }
                : null
            }
            ref={dropdownRef}
          >
            <div>
              <p className="class-name">
                {data.class} • {data.credit} SKS
              </p>
              {data.lecturer.map((lecturer) => (
                <div
                  key={crypto.randomUUID()}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (data.classes.length > 0) {
                      setShowDropdown(!showDropdown);
                    }
                  }}
                >
                  <p className="instructor">{lecturer}</p>
                </div>
              ))}
            </div>

            {data.classes.length > 0 ? (
              <IoMdArrowDropdown className="dropdown-class" />
            ) : null}
          </div>

          {showDropdown && (
            <div className="dropdown-class-item">
              {data.classes.map((item) => (
                <div
                  key={crypto.randomUUID()}
                  className="class-item class-click"
                  onClick={() => {
                    changeClassClick(item.name);
                  }}
                >
                  <div>
                    <p className="class-name">{item.name}</p>
                    {item.lecturer.map((lecturer) => (
                      <p key={crypto.randomUUID()} className="instructor">
                        {lecturer}
                      </p>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="icons">
          {/* <div className="icon-wrapper">
            <AiFillUnlock className="icon" size={20} />
          </div> */}
          <div className="icon-wrapper" onClick={removeCourse}>
            <FaTrash className="icon" size={18} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseItem;
