import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CourseDetailsService from "../../services/courseDetails.service";
import "./CourseDetailsStyle.css";
import ButtonAddCourse from "../../components/ButtonAddCourse/ButtonAddCourse";
import InstructorList from "./InstructorList";
import TimetableCourse from "../../components/TimetableCourse/TimetableCourse";
import LoadingPage from "../../components/LoadingPage/LoadingPage";
import ReactGA from "react-ga";

function CourseDetails() {
  const { course_code, curriculum } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setCourseDetails] = useState({
    prerequisite: [],
    semesters: {},
  });

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
    });
  }, []);

  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        const response = await CourseDetailsService.getCourseDetails(
          course_code,
          curriculum
        );
        setCourseDetails(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    setIsLoading(true);
    fetchCourseDetails();
  }, [course_code, curriculum]);

  const formattedFaculty = data.faculty
    ? data.faculty
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    : "";

  const formattedMajor = data.major
    ? data.major.replace(/\([^()]*\)/g, "").trim()
    : "";

  const placeholderDescription =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc congue eget lacus vel dignissim. Donec facilisis, tellus eu tincidunt vehicula, arcu purus tempor nisi, vitae ultrices massa massa et risus. Vivamus non gravida lorem. Maecenas at elementum metus. Quisque velit nisl, porttitor in vestibulum et, egestas non eros. Curabitur iaculis velit id odio eleifend, suscipit pretium est consectetur. Morbi venenatis ante ut ipsum interdum, a malesuada elit sagittis. Vivamus sed diam erat. Curabitur neque neque, vehicula a odio ut, accumsan varius nulla. Ut id augue sit amet augue malesuada feugiat.";

  return (
    <div className="course-details">
      {isLoading ? (
        <LoadingPage />
      ) : (
        <>
          <h2>{data.course_code}</h2>
          <h1>{data.name}</h1>
          <p className="pt-[12px]">
            {formattedFaculty} • {formattedMajor} • {data.credit} SKS
          </p>
          <div className="sem-container-details">
            {data.semesters
              ? Object.keys(data.semesters).map((sem) => (
                  <div className="sem-wrapper" key={crypto.randomUUID()}>
                    {sem !== 3 ? `Sem ${sem}` : "SP"}
                  </div>
                ))
              : null}
          </div>
          <div className="line"></div>

          <div className="course-details-container">
            <div>
              <p className="course-desc">
                {data.description !== ""
                  ? data.description
                  : placeholderDescription}
              </p>
              <div className="section-container prerequisite">
                <h6>Prerequisite</h6>
                {data.prerequisite.length > 0 ? (
                  data.prerequisite.map((prereq) => (
                    <p key={crypto.randomUUID()}>{prereq}</p>
                  ))
                ) : (
                  <p>No prerequisite</p>
                )}
              </div>
            </div>

            <div>
              <div className="section-container">
                <h6>Course Scope</h6>
                <p>{data.course_scope}</p>
              </div>
              <div className="section-container">
                <h6>Course Type</h6>
                <p>{data.course_type}</p>
              </div>
              <div className="section-container">
                <h6>For Term</h6>
                <p>{data.for_term}</p>
              </div>
              <ButtonAddCourse
                semester={data.semesters ? Object.keys(data.semesters) : []}
                data={data}
              />
            </div>
          </div>
          <h3>Instructors</h3>
          <div className="line-timetable"></div>
          {Object.keys(data.semesters).length > 0 && (
            <InstructorList semesters={data.semesters} />
          )}
          <h3>Timetable</h3>
          <div className="line-timetable"></div>
          {Object.keys(data.semesters).length > 0 && (
            <TimetableCourse semesters={data.semesters} />
          )}
        </>
      )}
    </div>
  );
}

export default CourseDetails;
