import React, { useState } from "react";
import "./TimetableDayStyle.css";
import ScheduleItem from "./ScheduleItem";

const generateNonClashingSchedules = (semester) => {
  const output = [];

  const sortedSemester = semester.sort((a, b) => {
    return a.start.localeCompare(b.start);
  });

  for (const schedule of sortedSemester) {
    let isAdded = false;

    for (const arr of output) {
      let clashFound = false;

      for (const existingSchedule of arr) {
        if (
          (schedule.start >= existingSchedule.start &&
            schedule.start < existingSchedule.end) ||
          (schedule.end > existingSchedule.start &&
            schedule.end <= existingSchedule.end)
        ) {
          clashFound = true;
          break;
        }
      }
      if (!clashFound) {
        arr.push(schedule);
        isAdded = true;
        break;
      }
    }

    if (!isAdded) {
      output.push([schedule]);
    }
  }

  return output;
};

const TimetableDay = ({
  day,
  minHour,
  maxHour,
  scheduleItems,
  colorDictionary,
  courseClasses,
  clickedCourse,
  setClickedCourse,
  timetableData,
  setTimetableData,
}) => {
  const dayTranslate = {
    Senin: "MON",
    Selasa: "TUE",
    Rabu: "WED",
    Kamis: "THU",
    Jumat: "FRI",
    Sabtu: "SAT",
    Minggu: "SUN",
  };

  const filteredScheduleItems = scheduleItems.filter(
    (item) => day === dayTranslate[item.day]
  );

  const scheduleItemsArr = generateNonClashingSchedules(filteredScheduleItems);

  return (
    <div
      className={
        scheduleItemsArr.length > 0
          ? "timetable-day-column"
          : "timetable-day-column timetable-day-empty"
      }
    >
      <div className="day-label">{day}</div>
      <div
        className="col-container"
        style={{ height: `calc(64px * (${maxHour - minHour + 1}))` }}
      >
        {scheduleItemsArr.map((schedule) => (
          <div className="col-item" key={crypto.randomUUID()}>
            {schedule.map((item, index) => (
              <ScheduleItem
                key={crypto.randomUUID()}
                minHour={minHour}
                maxHour={maxHour}
                data={item}
                colorDictionary={colorDictionary}
                courseClasses={courseClasses}
                clickedCourse={clickedCourse}
                setClickedCourse={setClickedCourse}
                timetableData={timetableData}
                setTimetableData={setTimetableData}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TimetableDay;
