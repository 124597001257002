import React from "react";
import "./TimetableSemesterStyle.css";
import TimeLabel from "./TimeLabel";
import DayColumn from "./DayColumn";

const TimetableSemester = ({ semesters, semesterSelected }) => {
  const courseColor = [
    "red",
    "orange",
    "sun",
    "green",
    "mint",
    "sea",
    "lightbrown",
    "pastel",
    "blue",
    "brown",
    "purple",
    "darkblue",
  ];

  const semester = semesters[semesterSelected];
  const classNames = new Set();
  semester.forEach((course) => {
    course.schedule_items.forEach((schedule) => {
      classNames.add(course.name);
    });
  });

  const uniqueClassNames = Array.from(classNames);

  const colorDictionary = {};
  for (let i = 0; i < uniqueClassNames.length; i++) {
    const className = uniqueClassNames[i];
    const colorIndex = i % courseColor.length;
    const color = courseColor[colorIndex];
    colorDictionary[className] = color;
  }

  let minStartTime = Infinity;
  let maxEndTime = -Infinity;

  semester.forEach((course) => {
    course.schedule_items.forEach((item) => {
      const startTime = parseFloat(item.start);
      const endTime = parseFloat(item.end);

      if (startTime < minStartTime) {
        minStartTime = Math.floor(startTime);
      }

      if (endTime > maxEndTime) {
        maxEndTime = Math.ceil(endTime);
      }
    });
  });

  return (
    <div className="timetable-semester-course">
      <TimeLabel minHour={minStartTime} maxHour={maxEndTime} />
      <div className="days">
        <DayColumn
          day="MON"
          semesters={semesters}
          semesterSelected={semesterSelected}
          colorDictionary={colorDictionary}
          minHour={minStartTime}
          maxHour={maxEndTime}
        />
        <DayColumn
          day="TUE"
          semesters={semesters}
          semesterSelected={semesterSelected}
          colorDictionary={colorDictionary}
          minHour={minStartTime}
          maxHour={maxEndTime}
        />
        <DayColumn
          day="WED"
          semesters={semesters}
          semesterSelected={semesterSelected}
          colorDictionary={colorDictionary}
          minHour={minStartTime}
          maxHour={maxEndTime}
        />
        <DayColumn
          day="THU"
          semesters={semesters}
          semesterSelected={semesterSelected}
          colorDictionary={colorDictionary}
          minHour={minStartTime}
          maxHour={maxEndTime}
        />
        <DayColumn
          day="FRI"
          semesters={semesters}
          semesterSelected={semesterSelected}
          colorDictionary={colorDictionary}
          minHour={minStartTime}
          maxHour={maxEndTime}
        />
      </div>
    </div>
  );
};

export default TimetableSemester;
