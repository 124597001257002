import React from "react";
import "./FooterStyle.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <p className="footer-text">
          Designed and built with all the tears 😢, sweat 💦 and love ❤️ by{" "}
          <a href="https://github.com/nafisazizir" target="_blank" className="footer-link">@nafisazizir</a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
