import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import CoursesDataService from "../../services/courses.service";
import "./CoursesStyle.css";
import Filter from "../../components/Filter/Filter";
import SearcBar from "../../components/SearchBar/SearcBar";
import CourseCard from "../../components/CourseCard/CourseCard";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import FilterMobile from "../../components/Filter/FilterMobile";
import NoDataFeedback from "../../components/NoDataFeedback/NoDataFeedback";
import SyncLoader from "react-spinners/SyncLoader";
import ReactGA from "react-ga4";

const Courses = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const [data, setData] = useState([]);
  const [courses, setCourses] = useState([]);
  const [currentPage, setCurrentPage] = useState(
    parseInt(queryParams.page) || 1
  );
  const [title, setTitle] = useState(queryParams.title || "");
  const [semesterFilters, setSemesterFilters] = useState([1, 2, 3]);
  const [facultyFilters, setFacultyFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    retrieveCourses(currentPage, title, semesterFilters, facultyFilters);
  }, [currentPage, title, semesterFilters, facultyFilters]);

  useEffect(() => {
    const queryParams = queryString.stringify({
      ...queryString.parse(location.search),
      page: currentPage,
      title: title,
      sem: semesterFilters,
      fac: facultyFilters,
    });

    navigate(`/courses?${queryParams}`);
    window.scrollTo(0, 0);
  }, [currentPage, title, semesterFilters, facultyFilters]);

  const getRequestParams = (page, title, semesterFilters, facultyFilters) => {
    let params = {};

    if (page) {
      params["page"] = page - 1;
    }

    if (title) {
      params["title"] = title;
    }

    if (semesterFilters) {
      params["sem"] = semesterFilters;
    }

    if (facultyFilters) {
      params["fac"] = facultyFilters;
    }

    return params;
  };

  const retrieveCourses = async (
    page,
    title,
    semesterFilters,
    facultyFilters
  ) => {
    CoursesDataService.getAllCourses(
      await getRequestParams(page, title, semesterFilters, facultyFilters)
    )
      .then((response) => {
        setData(response.data);
        setCourses(response.data.courses);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (event) => {
    const newTitle = event.target.value;
    setTitle(newTitle);
    setCurrentPage(1);
  };

  const handleSemesterFilterChange = (semester) => {
    if (semesterFilters.includes(semester)) {
      setSemesterFilters(
        semesterFilters.filter((filter) => filter !== semester)
      );
    } else {
      setSemesterFilters([...semesterFilters, semester]);
    }
    setCurrentPage(1);
  };

  const handleFacultyFilterChange = (faculty) => {
    if (facultyFilters.includes(faculty)) {
      setFacultyFilters(facultyFilters.filter((filter) => filter !== faculty));
    } else {
      setFacultyFilters([...facultyFilters, faculty]);
    }
    setCurrentPage(1);
  };

  const CustomPaginationItem = (props) => {
    const { page, type, ...rest } = props;

    if (page === 1 && type === "page" && data.currentPage - 1 > 1) {
      return null;
    } else if (
      page === data.totalPages &&
      type === "page" &&
      data.totalPages - data.currentPage > 1
    ) {
      return null;
    }

    return <PaginationItem page={page} type={type} {...rest} />;
  };

  return (
    <>
      <div className="courses-container">
        <div className="left-column">
          <Filter
            semesterFilters={semesterFilters}
            onSemesterFilterChange={handleSemesterFilterChange}
            facultyFilters={facultyFilters}
            onFacultyFilterChange={handleFacultyFilterChange}
          />
        </div>
        <div className="right-column">
          <SearcBar
            coursesCount={data.totalItems}
            value={title}
            onChange={handleSearchChange}
          />
          {Object.keys(courses).length > 0 ? (
            courses.map((course) => {
              const key = course.course_code + "/" + course.curriculum;
              return (
                <CourseCard
                  key={key}
                  courseCode={course.course_code}
                  courseName={course.name}
                  faculty={course.faculty}
                  major={course.major}
                  credit={course.credit}
                  semester={course.semester}
                  description={course.description}
                  curriculum={course.curriculum}
                />
              );
            })
          ) : isLoading ? (
            <SyncLoader
              loading={isLoading}
              color="#ffd900"
              cssOverride={{
                color: "var(--yellow-primary)",
                width: "fit-content",
                margin: "32px auto",
              }}
              size={12}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          ) : (
            <NoDataFeedback />
          )}
        </div>
      </div>
      <div className="flex justify-center items-center">
        <Pagination
          className="custom-pagination"
          count={data.totalPages}
          page={currentPage}
          shape="rounded"
          hideNextButton={data.hasNextPage ? false : true}
          hidePrevButton={data.hasPrevPage ? false : true}
          showFirstButton={data.hasPrevPage ? true : false}
          showLastButton={data.hasNextPage ? true : false}
          onChange={handlePageChange}
          renderItem={(props) => <CustomPaginationItem {...props} />}
        />
      </div>
      <FilterMobile
        semesterFilters={semesterFilters}
        onSemesterFilterChange={handleSemesterFilterChange}
        facultyFilters={facultyFilters}
        onFacultyFilterChange={handleFacultyFilterChange}
      />
    </>
  );
};

export default Courses;
