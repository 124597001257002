import React, { useState } from "react";
import "./SearchBarStyle.css";
import { FiSearch } from "react-icons/fi";

const SearchBar = ({ coursesCount, value, onChange }) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  return (
    <>
      <div className={`search-container ${isFocused ? "focused" : ""}`}>
        <FiSearch
          size={24}
          className={isFocused ? "search-icon-focused" : "search-icon"}
        />
        <input
          className="search-input"
          placeholder="Course name or course code"
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          value={value}
          onChange={onChange}
        />
      </div>
      <div className="search-count">
        <div>
          <p>{coursesCount} courses found</p>
        </div>
      </div>
    </>
  );
};

export default SearchBar;
