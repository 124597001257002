import React from "react";
import "./TimeLabelStyle.css";

const TimeLabel = ({ minHour, maxHour }) => {
  const timeIntervals = [];
  for (let hour = minHour; hour <= maxHour; hour++) {
    for (let minute = 0; minute < 60; minute += 60) {
      timeIntervals.push(
        `${hour.toString().padStart(2, "0")}${minute
          .toString()
          .padStart(2, "0")}`
      );
    }
  }
  return (
    <div
      className="time-label"
      style={{ gridTemplateRows: `repeat(${maxHour - minHour + 1}, 56px)` }}
    >
      {timeIntervals.map((time) => (
        <div key={crypto.randomUUID()}>{time}</div>
      ))}
    </div>
  );
};

export default TimeLabel;
