import { toPng } from "html-to-image";

export function convertToImage() {
  toPng(document.getElementById("timetable-export-original"), {
    backgroundColor: "white",
  })
    .then((dataUrl) => {
      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = "my-timetable.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // setTimeout(() => {
      //   window.close();
      // }, 1000);
    })
    .catch((err) => {
      console.log(err);
    });
}
