import React, { useState, useEffect } from "react";
import "./TimetableStyle.css";
import TimetableBox from "../../components/Timetable/TimetableBox";
import TimetableAction from "../../components/Timetable/TimetableAction";
import courseDetailsService from "../../services/courseDetails.service";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import { ToastContainer } from "react-toastify";
import LoadingPage from "../../components/LoadingPage/LoadingPage";
import ReactGA from "react-ga";

const Timetable = ({ semNumber }) => {
  const [scheduleItems, setScheduleItems] = useState([]);
  const [courseClasses, setCourseClasses] = useState({});
  const [clickedCourse, setClickedCourse] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const courseColor = [
    "red",
    "orange",
    "sun",
    "green",
    "mint",
    "sea",
    "lightbrown",
    "pastel",
    "blue",
    "brown",
    "purple",
    "darkblue",
  ];

  const [timetableData, setTimetableData] = useState(() => {
    const getDataFromLocalStorage = () => {
      setIsLoading(true);
      let localData = localStorage.getItem("courseData");
      localData = JSON.parse(localData);
      setIsLoading(false);
      return localData ? localData[semNumber] : [];
    };

    const storedData = getDataFromLocalStorage();
    return storedData ? storedData : [];
  });

  // load data from local storage
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
    });

    const getDataFromLocalStorage = () => {
      setIsLoading(true);
      let localData = localStorage.getItem("courseData");
      localData = JSON.parse(localData);
      setIsLoading(false);
      return localData ? localData[semNumber] : [];
    };

    const localData = getDataFromLocalStorage();
    setTimetableData(localData);
  }, [semNumber]);

  // save data to local storage
  useEffect(() => {
    localStorage.setItem(
      "courseData",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("courseData")),
        [semNumber]: timetableData,
      })
    );
  }, [semNumber, timetableData]);

  const getColorDictionary = (timetableData) => {
    const colorDictionary = {};

    for (const data of timetableData) {
      const { course_code, curriculum } = data;
      const key = course_code + "/" + curriculum;
      const color =
        courseColor[Object.keys(colorDictionary).length % courseColor.length];
      colorDictionary[key] = color;
    }

    return colorDictionary;
  };

  const colorDictionary = getColorDictionary(timetableData);

  // fetching the details of courses
  useEffect(() => {
    const fetchCourseDetails = async () => {
      const scheduleItemsData = [];
      const courseClassesData = {};

      for (const data of timetableData) {
        const { course_code, curriculum, class: className } = data;

        try {
          const response = await courseDetailsService.getCourseDetails(
            course_code,
            curriculum
          );
          const courseDetails = response.data;

          if (courseDetails && courseDetails.semesters) {
            const semesterItems = courseDetails.semesters[semNumber] || [];
            const filteredItems = semesterItems
              .filter((item) => item.name === className)
              .flatMap((item) => item.schedule_items);

            for (const item of filteredItems) {
              item["course_name"] = courseDetails["name"];
              item["course_code"] = course_code;
              item["curriculum"] = curriculum;
              item["class"] = className;
              item["isAdded"] = true;
              scheduleItemsData.push(item);
            }

            const key = course_code + "/" + curriculum;
            courseClassesData[key] = {
              course_name: courseDetails.name,
              credit: courseDetails.credit,
              classes: courseDetails.semesters[semNumber],
            };
          }
        } catch (error) {
          console.log(error);
        }
      }
      setScheduleItems(scheduleItemsData);
      setCourseClasses(courseClassesData);
    };

    fetchCourseDetails();
  }, [semNumber, timetableData]);

  // behavior when clickedCourse is change
  useEffect(() => {
    if (clickedCourse !== "") {
      let scheduleItemsData = [...scheduleItems];
      const course_code = clickedCourse.split("/")[0];
      const curriculum = clickedCourse.split("/")[1];
      const scheduleClicked = courseClasses[clickedCourse]["classes"];
      const course_name = courseClasses[clickedCourse]["course_name"];

      if (scheduleClicked.length > 0) {
        for (const item of scheduleClicked) {
          const { name } = item;
          const foundItem = timetableData.find((data) => data.class === name);
          if (!foundItem) {
            const schedules = item.schedule_items;
            for (const schedule of schedules) {
              schedule["course_name"] = course_name;
              schedule["course_code"] = course_code;
              schedule["curriculum"] = curriculum;
              schedule["class"] = name;
              schedule["isAdded"] = false;
              scheduleItemsData.push(schedule);
            }
          }
        }
      }
      setScheduleItems(scheduleItemsData);
    } else {
      let scheduleItemsData = [...scheduleItems];
      scheduleItemsData = scheduleItemsData.filter((item) => item.isAdded);
      setScheduleItems(scheduleItemsData);
    }
  }, [clickedCourse]);

  const handlePreviousClick = () => {
    if (semNumber === 2) {
      window.location.href = "/timetable/sem-1";
    } else if (semNumber === 3) {
      window.location.href = "/timetable/sem-2";
    }
  };

  const handleNextClick = () => {
    if (semNumber === 1) {
      window.location.href = "/timetable/sem-2";
    } else if (semNumber === 2) {
      window.location.href = "/timetable/sp";
    }
  };

  return (
    <div className="timetable">
      {isLoading ? <LoadingPage /> : null}
      <div
        className="semester-pagination"
        style={
          isLoading
            ? {
                display: "none",
              }
            : {}
        }
      >
        <MdNavigateBefore
          className={semNumber === 1 ? "arrow-disable" : "arrow-active"}
          size={24}
          onClick={semNumber !== 1 ? handlePreviousClick : null}
        />
        <h5>Semester {semNumber !== 3 ? semNumber : "Pendek"}</h5>
        <MdNavigateNext
          className={semNumber === 3 ? "arrow-disable" : "arrow-active"}
          size={24}
          onClick={semNumber !== 3 ? handleNextClick : null}
        />
      </div>
      <div className="timetable-container" id="timetable-container">
        <TimetableBox
          semester={semNumber}
          timetableData={timetableData}
          setTimetableData={setTimetableData}
          colorDictionary={colorDictionary}
          scheduleItems={scheduleItems}
          courseClasses={courseClasses}
          clickedCourse={clickedCourse}
          setClickedCourse={setClickedCourse}
        />
        <TimetableAction
          semester={semNumber}
          timetableData={timetableData}
          courseClasses={courseClasses}
          colorDictionary={colorDictionary}
          setTimetableData={setTimetableData}
          scheduleItems={scheduleItems}
        />
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="light"
      />
    </div>
  );
};

export default Timetable;
